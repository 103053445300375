import "../css/AboutMeLong.css"


export default function MyProjects(){
    return (
        <>
            <div className="aboutMeLong_back" id="AboutMeLong">
                <div className="aboutMeLong_block">
                    <h1>Мой опыт разработки</h1>
                    <p className="start_text">С самого детства занимался робототехникой; участвовал в множестве соревнований таких как: WRO, Profest, Робофинист и др.</p>
                    <div className="aboutMeLong_cont">
                        <div className="aboutMeLong_box">
                            <div className="aboutMeLong_box_in">
                                <h2>👁️</h2>
                                <h2>Машинное зрение</h2>
                            </div>
                            <p>В 15 лет писал машинное зрение с помощью opencv на ROS для робота, в основе которого была rasberry pi 3.</p>
                        </div>
                        <div className="photo_block_mobile">
                            <div className="photo1"></div>
                        </div>
                        <div className="aboutMeLong_box">
                            <div className="aboutMeLong_box_in">
                                <h2>💻</h2>
                                <h2>Stm32 разработчик</h2>
                            </div>
                            <p>В 16-17 лет работал на компанию программистом микроконтроллеров серии stm32f103, этот промежуток времени считаю одним из самых лучших в своей жизни.</p>
                        </div>
                        <div className="photo_block_mobile">
                            <div className="photo4"></div>
                        </div>
                    </div>
                    <div className="aboutMeLong_photo">
                        <div className="photo_block">
                            <div className="photo1"></div>
                        </div>
                        <div className="photo_block">
                            <div className="photo2"></div>
                            <div className="photo3"></div>
                        </div>
                        <div className="photo_block">
                            <div className="photo4"></div>
                        </div>
                    </div>
                    <div className="aboutMeLong_cont">
                        <div className="aboutMeLong_box">
                            <div className="aboutMeLong_box_in">
                                <h2>🤯</h2>
                                <h2>Что теперь?</h2>
                            </div>
                            <p>Сейчас активно изучаю web и разрабатываю свои проекты.</p>
                        </div>
                        <div className="aboutMeLong_box">
                            <div className="aboutMeLong_box_in">
                                <h2>💛</h2>
                                <h2>Для чего я всем этим занимаюсь?</h2>
                            </div>
                            <p>Все очень просто, мне нравится создавать что-то новое, то что приносит пользу.</p>
                        </div>
                    </div>
                    <h1 className="aboutMeLong_textframe">Любимый фреймворк</h1>
                    <div className="aboutMeLong_frame">
                        <p>AdonisJS - это веб-фреймворк на основе TypeScript для создания веб-приложений и серверов API. Он поставляется с поддержкой тестирования, современными инструментами, экосистемой официальных пакетов и многим другим.</p>
                        <div className="aboutMeLong_frame_image"></div>
                    </div>
                </div>
            </div>
        </>
    )
}