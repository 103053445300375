import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import reportWebVitals from './js/reportWebVitals';
import Cap from "./js/Cap";
import AboutMe from './js/AboutMe';
import MyProjects from './js/MyProjects';
import Stek from './js/Stek';
import Steps from './js/Steps'
import AboutMeLong from './js/AboutMeLong';
import Futer from "./js/Futer";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Cap name="ArseniyIgnatev"/>
    <AboutMe/>
    <AboutMeLong />
    <MyProjects />
    <Stek />
    <Steps />
    <Futer />
  </React.StrictMode>
);

reportWebVitals();
