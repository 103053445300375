import "../css/Stek.css";
export default function Stek(){
    return (
        <>
            <div className="Stek_back" id="Stek">
                <div className="stek_contain">
                    <h1>Стэк технологий</h1>
                    <div className="stek_cont">
                        <div className="stek_out">
                            <div className="stek_in">
                                <div className="stek_nodejs"></div>
                                <h2> - Node.js</h2>
                            </div>
                            <div className="stek_in">
                                <div className="stek_expressjs"></div>
                                <h2> - Express.js</h2>
                            </div>
                            <div className="stek_in">
                                <div className="stek_adonisjs"></div>
                                <h2> - Adonis.js</h2>
                            </div>
                            <div className="stek_in_mobile">
                                <div className="stek_git"></div>
                                <h2> - Git</h2>
                            </div>
                            <div className="stek_in_mobile">
                                <div className="stek_nginx"></div>
                                <h2> - Nginx</h2>
                            </div>
                        </div>
                        <div className="stek_out">
                            <div className="stek_in">
                                <div className="stek_react"></div>
                                <h2> - React</h2>
                            </div>
                            <div className="stek_in">
                                <div className="stek_front"></div>
                                <h2> - Html, css, js</h2>
                            </div>
                            <div className="stek_in">
                                <div className="stek_sql"></div>
                                <h2> - SQL(Mysql, Postgresql)</h2>
                            </div>
                            <div className="stek_in_mobile">
                                <div className="stek_docker"></div>
                                <h2> - Docker</h2>
                            </div>
                        </div>
                        <div className="stek_out_comp">
                            <div className="stek_in">
                                <div className="stek_git"></div>
                                <h2> - Git</h2>
                            </div>
                            <div className="stek_in">
                                <div className="stek_nginx"></div>
                                <h2> - Nginx</h2>
                            </div>
                            <div className="stek_in">
                                <div className="stek_docker"></div>
                                <h2> - Docker</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}