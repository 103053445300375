import "../css/Cap.css";
import React, {useState} from 'react'

export default function Cap({name}){ 

    var [menuOpen, setMenuOpen] = useState(false);
    return (
        <>
            <div className="out">
                <div className="in">
                    <div className="FlexObjects" id="FlexObjects">
                        <h2>{name}</h2>
                        <div className="titles">
                            <a href="#AboutMe" className="text_out">
                                <p>Обо мне</p>
                            </a>
                            <a href="#AboutMeLong" className="text_out">
                                <p>Опыт</p>
                            </a>
                            <a href="#MyProjects" className="text_out">    
                                <p>Мои проекты</p>
                            </a>
                            <a href="#Stek" className="text_out">
                                <p>Стэк технологий</p>
                            </a>
                        </div>
                        <div className="titles_mobile" onPointerDown={() => setMenuOpen(menuOpen = !menuOpen)}></div>
                    </div>
                </div>
            </div>
            {menuOpen && (
                <div className="menu_mobile">
                    <div className="span_mobile"></div>
                    <a href="#AboutMe" className="text_out_mobile" onClick={() => setMenuOpen(menuOpen = false)}>
                        <p>Обо мне</p>
                    </a>
                    <a href="#AboutMeLong" className="text_out_mobile" onClick={() => setMenuOpen(menuOpen = false)}>
                        <p>Опыт</p>
                    </a>
                    <a href="#MyProjects" className="text_out_mobile" onClick={() => setMenuOpen(menuOpen = false)}>    
                        <p>Мои проекты</p>
                    </a>
                    <a href="#Stek" className="text_out_mobile" onClick={() => setMenuOpen(menuOpen = false)}>
                        <p>Стэк технологий</p>
                    </a>
                </div>
            )}
        </>
    );
}


