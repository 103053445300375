import "../css/MyProjects.css"

export default function MyProjects(){
    return (
        <>
            <div className="projects_back" id="MyProjects">
                <div className="projects_image"></div>
                <div className="projects_contain">
                    <h1>Один из моих проектов - SmartCubePay</h1>
                    <div className="projects_cont">
                        <div className="proj_pay">
                            <h2>Что это?</h2>
                            <p>SmartCubePay - это платежная система, работающая на blockchain, позволяющая оплачитвать покупки криптовалютой.</p>
                            <div className="scrit_pay">
                                <p>Все работа с блокчейном реализована на бибилиотеке web3.</p>
                            </div>
                        </div>
                        <div className="projects_gif"></div>
                        <div className="proj_nad">
                            <h2>Методы оплаты</h2>
                            <p>Оплатить можно ETH(Arbitrum, Base, Optimism, Zksync), BNB, MATIC. Благодоря layer2, платеж пройдет моментально.</p>
                            <div className="scrit_nad">
                                <p>Продавцам токены приходят в течении пары минут.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}