import "../css/Steps.css"

export default function Steps(){
    return (
        <>
            <div className="steps_back">
                <div className="steps_cont">
                    <h1>Шаги для создания этого вебсайта.</h1>
                    <div class="container">
                        <div class="box">
                            <div class="content">
                                <h2>Макет</h2>
                                <p>Самый первый шаг - это создание макета. Макет был создан в графическом редакторе Figma.</p>
                            </div>
                        </div>
                        <div class="box">
                            <div class="content">
                            <h2>Конструкция</h2>
                            <p>Далее сайт был написан на Node.js с помощью фреймворка React с использованием  JSX и библиотеки JQuery.</p>
                            </div>
                        </div>
                        <div class="box">
                            <div class="content">
                            <h2>Деплой</h2>
                            <p>И наконец сайт был выгружен на VPS сервер и настроен с помощью nginx и pm2.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}