import "../css/AboutMe.css";

export default function AboutMe(){
    return (
        <>
            <div className="main_cont" id="AboutMe">
                <div className="back"></div>
                <div className="aboutme_contain">
                    <h1 className="tx">Немного обо мне</h1>
                    <div className="radelit">
                        <div className="squire_cont">
                            <div className="squire"></div>
                            <p className="figure_text">Меня зовут Арсений, стремлюсь стать сильным разработчиком устройств и приложений.</p>
                        </div>
                        <div className="round_cont">
                            <div className="round"></div>
                            <p className="figure_text">Backend пишу на node.js(express.js, adonis.js), frontend - React.js.</p>
                        </div>   
                        <div className="triangle_cont">
                            <div className="triangle">&#9650;</div>
                            <p className="figure_text">Активно создаю и развиваю свои проекты.</p>
                        </div>
                        <div className="star_cont">
                            <div className="star">★</div>
                            <p className="figure_text">Хочу открыть свою IT компанию.</p>
                        </div>
                    </div>
                    <a className="about_me_button" href="#Futer">
                        <p>Связаться со мной</p>
                    </a>
                </div>
            </div>
        </>
    );
}
