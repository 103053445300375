import "../css/Futer.css"

export default function MyProjects(){
    return (
        <>
            <div className="futer_back" id="Futer">
                <div className="futer_contain">
                    <div className="futer_cont">
                        <div className="futer_contacts">
                            <h2>Контакты:</h2>
                            <div className="futer_images">
                                <a href="https://t.me/sakshi_vfx" className="futer_tg"></a>
                                <a href="https://wa.me/79527595491" className="futer_wh"></a>
                            </div>
                            <a href="tel:89527595491">+7 952 759 54 91</a>
                            <a href="https://t.me/sakshi_vfx">Написать в телеграмм</a>
                            <a href="https://wa.me/79527595491">Написать в ватсап</a>
                            <a href="https://t.me/ArseniyIgnatev">Телеграмм канал</a>
                        </div>
                        <div className="futer_contacts">
                            <h2>Остальные источники:</h2>
                            <div className="futer_images">
                                <a href="https://github.com/IgnatevArseniy" className="futer_git"></a>
                                <a href="https://vc.ru/u/2570003-arseniy-ignatev" className="futer_vc"></a>
                            </div>
                            <a href="https://github.com/IgnatevArseniy">Мой github</a>
                            <a href="https://vc.ru/u/2570003-arseniy-ignatev">Статьи на vc.ru</a>
                            <a href="https://teletype.in/@arseniyignatev">Статьи на teletype</a>
                        </div>
                        <div className="futer_contacts">
                            <h2>Структура:</h2>
                            <a href="#AboutMe">Немого о себе</a>
                            <a href="#AboutMeLong">Мой опыт</a>
                            <a href="#MyProjects">SmartCubePay</a>
                            <a href="#Stek">Стек техгологий</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}